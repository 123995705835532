* {
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background: black;
  height: 100vh;
  color: white;
  font-weight: 300;
  line-height: 1.25;
  font-size: 0.92rem;
  overflow: hidden;

  #bg-vid {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  #bg-vid-poster {
    transition: opacity 200ms;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  #app {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }
}