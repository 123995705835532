@import "base";

body {
  position: relative;
}

.form-check-label {
  line-height: 1.5rem;
}

.form-check-input:checked {
  background-color: rgb(240, 40, 75);
  border-color: rgb(240, 40, 75);
}

.form-check-input:focus {
  border-color: rgb(240, 40, 75);
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(240, 40, 75, .2);
}

.form-control {
  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: rgb(240, 40, 75);
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(240, 40, 75, .2);
  }
}

.btn {
  &:hover {
    border-color: rgb(240, 40, 75);
    background: rgb(240, 40, 75);
    color: white;
  }
}

#login {
  font-size: 0.875rem;
  font-weight: 300;
  border-radius: 9999px;
  background: #ffffff25;
  padding: .5rem .75rem;

  &:hover {
    --bg-opacity: 1;
    background-color: rgb(240, 40, 75);
    background-color: rgba(240, 40, 75, var(--bg-opacity));
  }
}


.logo-container {
  width: 318px;
  height: 71px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1.25rem 2rem 1.25rem 0;

  #logo {
    height: 100%;
    width: 100%;
    background: url('/img/LogoText.svg') center center no-repeat;
    background-size: contain;

    &:hover {
      background: url('/img/LogoText-Colored.svg') center center no-repeat;
    }
  }

  .copyright-notice {
    position: absolute;
    right: 0;
    bottom: 2px;
    color: #414141;
    font-size: 14px;
    font-weight: 500;
    font-family: "Open Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased;
  }
}
